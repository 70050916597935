import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import i18n from 'src/i18n/i18next'

export default function IndexPage() {
    useEffect(() => {
        navigate(`/${i18n.language}/`);
    }, []);

    return null;
}
